import { useCallback, useState, useEffect } from "react";
import { getRequest } from "../../utils/httpUtils";
import { uniqueId } from "lodash";

const isValidString = str => typeof str === "string" && str.trim().length > 0;

const useFetchFootnotes = (query, params = {}) => {
    const [footnotes, setFootnotes] = useState([]);
    const [footnotesCount, setFootnotesCount] = useState(0);
    const [companiesCount, setCompaniesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loadingIDs, setLoadingIDs] = useState([]);
    const { footnoteTitles, companies, years, industries, isPublic } = params;

    const fetchFootnotes = useCallback(async () => {
        const requestID = uniqueId();
        setFootnotes([]);
        setLoadingIDs(loadingIDs => [...loadingIDs, requestID]);
        try {
            const params = new URLSearchParams();
            params.append("page", currentPage);
            params.append("page_size", pageSize);
            console.log(footnoteTitles, companies);
            if (isValidString(query)) {
                params.append("query", query.trim());
            }
            (footnoteTitles || []).forEach((title) => {
                params.append("footnote_title", title);
            });
            (companies || []).forEach((company) => {
                params.append("company", company);
            });
            (years || []).forEach((year) => {
                params.append("year", year);
            });
            (industries || []).forEach((industry) => {
                params.append("industry", industry);
            });
            if (isPublic) {
                params.append("is_public", true);
            }
            const queryString = params.toString();
            const response = await getRequest(`/api/footnotes/?${queryString}`);
            setFootnotes(response.data.footnotes);
            setFootnotesCount(response.data.count);
            setCompaniesCount(response.data.companies_count);
        }  finally {
            setLoadingIDs(loadingIDs => loadingIDs.filter(id => id !== requestID));
        }
    }, [currentPage, pageSize, query, footnoteTitles, companies, years, industries]);

    useEffect(() => {
        fetchFootnotes();
    }, [currentPage, pageSize]);

    useEffect(() => {
        setCurrentPage(1);
    }, [pageSize]);

    const navigateNext = useCallback(() => {
        setCurrentPage((prevPage) => prevPage + 1);
    }, []);

    const navigatePrevious = useCallback(() => {
        setCurrentPage((prevPage) => prevPage - 1);
    }, []);

    const setPageCallback = useCallback((page) => {
        if (page < 1 || page > Math.ceil(footnotesCount / pageSize)) {
            return;
        }
        setCurrentPage(page);
    }, [footnotesCount, pageSize]);

    const setPageSizeCallback = useCallback((size) => {
        if (size < 1) {
            return;
        }
        setPageSize(size);
    }, []);

    const resetToFirstPage = useCallback(() => {
        setCurrentPage(1);
    }, []);

    const hasNextPage = footnotesCount > currentPage * pageSize;
    const hasPreviousPage = currentPage > 1;
    const totalPages = Math.ceil(footnotesCount / pageSize);

    return {
        footnotes,
        footnotesCount,
        fetchFootnotes,
        navigateNext,
        setCurrentPage: setPageCallback,
        setPageSize: setPageSizeCallback,
        navigatePrevious,
        hasNextPage,
        hasPreviousPage,
        loading: loadingIDs.length > 0,
        totalPages,
        pageSize,
        companiesCount,
        resetToFirstPage,
    };
}
export default useFetchFootnotes;
// useAppList.js
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { getMyApps, myAppsSelector } from "../redux/reducers/apps.reducer";

const useAppList = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const appData = useSelector(myAppsSelector);

  useEffect(() => {
    // I don't think this is actually sending 'user'
    if (user) {
      dispatch(getMyApps({ user }));
    }
    else {
      dispatch(getMyApps({ user: null }));
    }
  }, [user, dispatch]);

  return appData;
};

export default useAppList;
import React, { useState } from "react";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";

const FootnotesList = ({ footnotes }) => {
  const [selectedFootnote, setSelectedFootnote] = useState(footnotes[0]);

  const handleFootnoteClick = (footnote) => {
    setSelectedFootnote(footnote);
  };
  const handleCloseDetailView = () => {
    setSelectedFootnote(null);
  };

  //console.log('HERE!' + footnotes[0]);

  return (
    <div className="flex h-screen">
      {/* Left Column - List */}
      <div className="w-1/2 overflow-y-auto border-r border-gray-300 bg-gray-50 p-4">
        {footnotes.map((footnote) => (
          <div
            key={footnote.id}
            onClick={() => handleFootnoteClick(footnote)}
            className={`cursor-pointer p-4 mb-4 bg-white   rounded-lg  transition-all ${
              selectedFootnote && selectedFootnote.id === footnote.id
                ? "border-2 border-customHighlightColor"
                : "border-2 border-gray-300"
            }`}
          >
            <h2 className="text-xl font-semibold text-purple-900">
              {footnote.footnote.title}
            </h2>
            <p className="text-sm font-bold text-purple-700">
              {footnote.date?.replace("00:00:00 GMT", "")}
            </p>
            <p className="text-sm text-gray-600">
              {footnote.footnote?.paragraphs[1]?.text.slice(0, 200) + "..."}
            </p>
            <p className="text-sm font-bold text-purple-700">
              {footnote.company_name}
            </p>
            <p className="text-sm text-gray-700">
              {selectedFootnote?.industry_name}
            </p>
          </div>
        ))}
      </div>

      {/* Right Column - Detail View */}
      <div className="w-1/2 overflow-y-auto flex flex-col h-full bg-white rounded-lg  shadow-lg border-2 border-customHighlightColor">
        <div className="p-4 flex-shrink-0 relative">
          <div className="relative">
            <button
              onClick={handleCloseDetailView}
              className="absolute top-0 right-0 -mt-2 -mr-2 text-gray-400 hover:text-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          {selectedFootnote ? (
            <div className="h-full p-6 bg-white rounded-lg  shadow-lg ">
              <a
                className="text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm"
                href={`https://tellencl.blob.core.windows.net/10kdatabase/${selectedFootnote.pdf_file_id}.pdf`}
              >
                <ArrowDownOnSquareIcon
                  className="h-6 w-6 shrink-0"
                  aria-hidden="true"
                />
              </a>
              <br />
              <p className="text-sm font-bold text-purple-700">
                {selectedFootnote.company_name}
              </p>
              <p className="text-sm text-gray-700">
                {selectedFootnote?.industry_name}
              </p>
              <h1 className="text-3xl font-bold text-purple-900 mb-4">
                {selectedFootnote?.footnote.title}
              </h1>
              <p className="text-sm font-bold text-purple-700">
                {selectedFootnote?.date?.replace("00:00:00 GMT", "")}
              </p>
              <div className="text-sm text-gray-700 space-y-2 mb-4">
                {selectedFootnote.footnote?.paragraphs.map(
                  (p, index) => index > 0 && <p key={index}>{p.text}</p>
                )}
              </div>
              <div className="mt-4">
                <img
                  src={`https://tellencl.blob.core.windows.net/10kdatabase/${selectedFootnote.screenshot_file_id}`}
                  alt="Footnote Screenshot"
                  className="w-full h-auto object-contain rounded-md"
                />
              </div>
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              <p className="text-gray-500">Click a footnote to see details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FootnotesList;
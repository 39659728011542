import React, { Suspense } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import { SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";

import Home from "../Home";
import InvoiceCrossReference from "../apps/crossReference/InvoiceCrossReference";
import AuditChecklist from "../AuditChecklist";
import BoardMinutesAnalyzer from "../apps/boardMinutesAnalyzer/BoardMinutesAnalyzer";
import PCAOBAnalyzer from "../apps/pcaobAnalyzer/pcaobAnalyzer";
import SignIn from "../SignIn";
import Profile from "../Profile";
import Logs from "../admin/Logs";
import Feedback from "../admin/Feedback";
import Apps from "../Apps";
import MyApps from "../MyApps";
import CreateApp from "../appManipulation/CreateApp";
import EditApp from "../appManipulation/EditApp";
import AppDetail from "../AppDetail";
import GeneralDataChatAnalyzer from "../apps/generalDataChatAnalyzer/GeneralDataChatAnalyzer";
import CompanyApps from "../CompanyApps";
import Changelog from "../screens/changelog/Changelog";
import PublishApp from "../appManipulation/PublishApp";
import FootnoteAI from "../apps/FootnoteAI/FootnoteAI";
import GenericExtractor from "../apps/GenericExtractor/GenericExtractor";
import FootnotesDatabase from "../apps/footnotesDatabase/FootnotesDatabase";

/**
 * Protects routes to ensure that only signed-in users can access them, redirecting
 * others to the sign-in page. It helps in removing the redundancy of checking
 * authentication status in every component.
 *
 * @param {{ children: JSX.Element }} props The child component(s) to be rendered within the protected route.
 * @returns {JSX.Element} A component that renders the children if the user is signed in, otherwise redirects to the sign-in page.
 */
const ProtectedRoute = ({ children }) => (
  <>
    <SignedIn>{children}</SignedIn>
    <SignedOut>
      <RedirectToSignIn redirectUrl="/" />
    </SignedOut>
  </>
);

const ProtectedRouteWithKey = ({ children }) => {
  const { instance_id, conversation_uuid } = useParams();
  const key = `${instance_id}-${conversation_uuid}`;
  return <ProtectedRoute key={key}>{children}</ProtectedRoute>;
};

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/board_minutes_analyzer/:instance_id?"
        element={
          <ProtectedRoute>
            <BoardMinutesAnalyzer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tenk_extractor/:instance_id"
        element={
          <ProtectedRoute key="Footnote Extractor">
            <GenericExtractor type="10k" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/footnote_ai/:instance_id"
        element={
          <ProtectedRoute key="Footnote AI">
            <FootnoteAI />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pdf_tables_to_csvs/:instance_id"
        element={
          <ProtectedRoute key="All Tables">
            <GenericExtractor type="tables" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/invoice_cross_reference/:instance_id?"
        element={
          <ProtectedRoute>
            <InvoiceCrossReference />
          </ProtectedRoute>
        }
      />
      <Route
        path="/audit_checklist/:instance_id?"
        element={
          <ProtectedRoute>
            <AuditChecklist />
          </ProtectedRoute>
        }
      />
      <Route
        path="/general_data_chat/:instance_id?/:conversation_uuid?"
        element={
          <ProtectedRouteWithKey>
            <GeneralDataChatAnalyzer fileType="General Data Chat" />
          </ProtectedRouteWithKey>
        }
      />
      {/* <Route
        path="/general_data_chat/:instance_id?/approved_qa"
        element={
          <ProtectedRouteWithKey>
            <ApprovedQuestionsAnswers />
          </ProtectedRouteWithKey>
        }
      /> */}
      <Route
        path="/pcaob_analzyer/:instance_id?"
        element={
          <ProtectedRouteWithKey>
            <PCAOBAnalyzer />
          </ProtectedRouteWithKey>
        }
      />
      <Route path="/apps_l1" element={<Apps layer={1} />} />
      <Route path="/apps_l2" element={<Apps layer={2} />} />
      <Route
        path="/myapps"
        element={
          // <ProtectedRoute key="App Select">
            <MyApps />
          // </ProtectedRoute>
        }
      />
      <Route
        path="/footnotes_database/:instance_id"
        element={
          <ProtectedRoute key="Footnotes Database">
            <FootnotesDatabase />
          </ProtectedRoute>
        }
      />
      <Route
        path="/10k-footnote-search/:instance_id?"
        element={<FootnotesDatabase isPublic={true} />}
      />
      <Route
        path="/company_apps"
        element={
          <ProtectedRoute key="Company App Select">
            <CompanyApps />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/create/:app"
        element={
          <ProtectedRoute key="Application">
            <CreateApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/edit/:instance_id"
        element={
          <ProtectedRoute key="Application Edit">
            <EditApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/publish/:app_instance_id"
        element={
          <ProtectedRoute key="Application Publish">
            <PublishApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/view/:app"
        element={
          <ProtectedRoute key="View Application">
            <AppDetail />
          </ProtectedRoute>
        }
      />
      <Route path="/sign_in" element={<SignIn />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin_users"
        element={
          <ProtectedRoute>
            <Logs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin_feedback"
        element={
          <ProtectedRoute>
            <Feedback />
          </ProtectedRoute>
        }
      />
      <Route path="/changelog" element={<Changelog />} />
    </Routes>
  );
};

export default Router;

// Constants for company files
const AMSWA = "AMSWA - 2024 - 10K.pdf";
const ABC = "ABC.pdf";
const XYZ = "XYZ.pdf";
const NCNO = "nCino.pdf";
const BIGC = "BigCommerce.pdf";

// Function constant
const getSuggestedFormatAndReason = (companyFile) => {
    let result = ["", ""]; // Default array with two empty elements for format and reason

    if (companyFile === AMSWA) {
        result = [
            "This format is not suggested because it deals with a broader range of lease disclosures than is necessary for the Target Company",
            "The company primarily utilizes operating leases for its facilities and also leases out a portion of its headquarters building. While the company recognizes lease assets and liabilities on its balance sheet, it chose not to include renewal options in these calculations, and its lease liabilities are primarily short-term."
        ];
    } else if (companyFile === ABC || companyFile === XYZ) {
        result = [
            "We suggest using this template. This lease disclosure template starts with an overview of the lease arrangement, then details its financial impact, the effect of new accounting standards, and key assumptions, culminating in a table showcasing the lease liability calculation. It aims to provide a clear and structured presentation of lease-related information for financial statement users. Additionally, this template was generated by Perelson Weiner and follows a structure that your firm is familiar with.",
            "The main topics of this disclosure are the company's operating lease for its office space and the financial impact of adopting the new lease accounting standard (ASC 842). It details the lease terms, associated costs, cash flows, and the resulting balance sheet recognition of a right-of-use asset and lease liability."
        ];
    } else if (companyFile === NCNO) {
        result = [
            "This template is a good alternative to consider using. This lease disclosure template begins with a brief overview of the lease arrangements and then presents a tabular breakdown of lease expenses and related cash flows, followed by a maturity analysis of future lease payments.",
            "This footnote details the company's operating leases, including the associated expenses and cash flows. It also highlights the impact of adopting the new lease accounting standard (ASC 842) and provides a maturity analysis of future minimum lease payments."
        ];
    } else if (companyFile === BIGC) {
        result = [
            "This template is not suggested since it covers topics not related to the Target Company. The template is organized into two sections: Leases and Restructuring Charges. It provides an overview of operating lease arrangements and details of two restructuring plans, including their financial implications.",
            "This footnote discusses the company's operating leases, including the associated expenses and future payment obligations. Additionally, it details two restructuring plans undertaken by the company, outlining the related costs, liabilities, and impairment charges incurred."
        ];
    } else {
        // Default case if no company matches
        result = [
            "This disclosure applies after the adoption of ASU 2016-13, Financial Instruments—Credit Losses (Topic 326): Measurement of Credit Losses on Financial Instruments, and replaces the disclosure in Question No. 4. The effective dates of the ASU are specified for Credit Losses on Financial Instruments Measured at Amortized Cost—For Years Beginning After December 15, 2022.",
            "This disclosure applies after the adoption of ASU 2016-13, Financial Instruments—Credit Losses (Topic 326): Measurement of Credit Losses on Financial Instruments, and replaces the disclosure in Question No. 4. The effective dates of the ASU are specified for Credit Losses on Financial Instruments Measured at Amortized Cost—For Years Beginning After December 15, 2022."
        ];
    }

    return result;
};

export default getSuggestedFormatAndReason;
/**
 * Reusable Tab component
 * @param {Object} props 
 * @param {Object[]} props.tabs - Array of tab objects, each containing `id` and `label`.
 * @param {string} props.currentTab - The currently active tab `id`.
 * @param {function} props.onClickTab - Callback function triggered on tab click. Receives the clicked tab object as parameter.
 * @returns {JSX.Element}
 */

const Tabs = ({ tabs, currentTab, onClickTab }) => {
  return (
    <nav aria-label="Tabs Progress">
      <ol
        role="list"
        className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
      >
        {tabs.map((tab, tabIdx) => (
          <li key={tab.id} className="relative md:flex md:flex-1">
            <a
              href="#"
              onClick={() => onClickTab(tab)}
              className="group flex w-full items-center no-underline"
            >
              {currentTab === tab.id ? (
                // Active tab (current step)
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-customHighlightColor">
                    <span className="text-customHighlightColor">
                      {tabIdx + 1}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-customHighlightColor no-underline">
                    {tab.label}
                  </span>
                </span>
              ) : (
                // Inactive tab
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">
                      {tabIdx + 1}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900 no-underline">
                    {tab.label}
                  </span>
                </span>
              )}
            </a>
            {tabIdx !== tabs.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  aria-hidden="true"
                  className="absolute right-0 top-0 h-full w-5 md:block"
                >
                  <svg
                    fill="none"
                    viewBox="0 0 22 80"
                    preserveAspectRatio="none"
                    className="h-full w-full text-gray-300"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      stroke="currentcolor"
                      vectorEffect="non-scaling-stroke"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};


export default Tabs;

  
import React from "react";
import cn from "classnames";
import {
  PaperAirplaneIcon,
  StarIcon,
  XCircleIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import ToggleFileComponent from "../../utils/ToggleFileComponent";
import PromptsDialog from "../../utils/PromptsDialog";

export const MessageForm = ({
  value,
  onChange,
  onStar,
  hideStar = false,
  onClear,
  onFileButtonClick,
  hideClear = false,
  onSubmit,
  placeholder,
  className,
  label,
  fullWidth = false,
  inputName = "query",
  inputRef,
  returnType,
  setReturnType,
  fetchSavedPrompts,
  onSelectPrompt,
  appInstanceId,
  savedPrompts,
}) => {
  return (
    <form onSubmit={onSubmit} className={className}>
      {label && (
        <p className="block text-gray-700 text-sm font-bold mb-0 my-2">
          {label}
        </p>
      )}
      <div className="flex my-2">
        {onFileButtonClick && (
          <button
            type="button"
            className="mr-2 shadow h-10 w-10 flex flex-col justify-center items-center rounded-full bg-white text-black"
            onClick={onFileButtonClick}
          >
            <PaperClipIcon
              className="h-4 w-4 shrink-0 text-customHighlightColor"
              aria-hidden="true"
            />
          </button>
        )}
        <PromptsDialog
          fetchSavedPrompts={fetchSavedPrompts}
          onSelectPrompt={onSelectPrompt}
          appInstanceId={appInstanceId}
          savedPrompts={savedPrompts}
        />
        <div
          className={cn("shadow flex relative rounded-full w-full bg-white mr-2", {
            "flex-1": fullWidth,
          })}
        >
          <input
            ref={inputRef}
            className="border-0 appearance-none rounded-full flex-1 py-2 px-3 text-gray-700 leading-tight outline-none focus:outline-none focus:shadow-outline focus:shadow-none"
            value={value}
            name={inputName}
            placeholder={placeholder || "Type a message"}
            onChange={onChange}
            data-testid="chat-msg-field"
          />

          {onStar && value.length > 0 && !hideStar ? (
            <button
              type="button"
              className="flex flex-col justify-center items-center self-center h-6 w-6 bg-zinc-200 mx-2 rounded-full text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm"
              onClick={onStar}
            >
              <StarIcon
                className="h-4 w-4 shrink-0 text-customHighlightColor"
                aria-hidden="true"
              />
            </button>
          ) : null}
        </div>
        {onClear && !hideClear && (
          <button
            type="button"
            title="Clear conversation"
            className="cursor-pointer mr-2 shadow h-10 w-10 flex flex-col justify-center items-center rounded-full bg-white text-black"
            onClick={onClear}
            data-testid="clear-chat-history-btn"
          >
            <XCircleIcon
              className="h-5 w-5 text-customHighlightColor"
              aria-hidden="true"
            />
          </button>
        )}
        <button
          type="submit"
          className="shadow h-10 w-10 flex flex-col justify-center items-center rounded-full bg-white text-black"
          data-testid="send-chat-msg-btn"
        >
          <PaperAirplaneIcon
            className="h-5 w-5 text-customHighlightColor"
            aria-hidden="true"
          />
        </button>
      </div>
      {setReturnType && (
        <div className="flex justify-end mt-4">
          <ToggleFileComponent
            setSelectedOption={setReturnType}
            selectedOption={returnType}
          />
        </div>
      )}
    </form>
  );
};
